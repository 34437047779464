<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col>
          <v-container class="spaces-list">
            <v-row :style="{ maxHeight: titleRowHeight }">
              <v-col class="spaces-list__heading" cols="8" :sm="7">
                <c-page-heading
                  :heading="$t('spaces.list.manage')"
                  :subHeading="$t('spaces.list.spaces')"
                >
                </c-page-heading>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="7" class="text-left">
                <div
                  class="spaces-list__sub-title font-weight-light"
                  data-cy="viewAddAndEditSpacesWithinYourEcosystem"
                >
                  {{ $t('spaces.list.viewAddAndEditSpacesWithinYourEcosystem') }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <m-table-view data-cy="spaces-list">
                  <template v-slot:toolbar>
                    <div class="table-toolbar">
                      <m-search-input
                        v-model="searchTerm"
                        :placeholder="$t('spaces.list.searchSpaces')"
                        class="table-search-bar"
                        @clear="searchTerm = null"
                      ></m-search-input>
                      <m-button
                        :label="$t('spaces.list.create')"
                        icon="plus-stroke"
                        iconRight
                        data-cy="add-space-icon"
                        @click="createSpace"
                      ></m-button>
                    </div>
                  </template>
                  <template #default>
                    <m-table
                      class="spaces-list__table"
                      :items="filteredSpaces"
                      :headers="headers"
                      :page.sync="pageNumber"
                      :items-per-page="+perPage"
                      :disablePagination="false"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      @current-items="setDisplayedSpaces"
                      @click:row="editSpace($event)"
                    >
                      <template #item.name="{ item }">
                        <a
                          :data-cy="`edit-${item.name}`"
                          class="space-name"
                          @click="editSpace(item)"
                          >{{ item.name }}</a
                        >
                      </template>
                      <template #item.attendees="{ item }">
                        {{ item.attendees?.length }}
                      </template>

                      <template #item.menu="{ item }">
                        <m-button
                          variant="textOnly"
                          icon="edit-stroke"
                          @click="editSpace(item)"
                          data-cy="space-edit"
                        ></m-button>
                      </template>
                    </m-table>
                  </template>

                  <template #footer>
                    <v-divider class="mb-5"></v-divider>

                    <m-pagination
                      :total="filteredSpaces.length"
                      :pageNumber="pageNumber"
                      :perPage="perPage"
                      @prev="pageNumber--"
                      @next="pageNumber++"
                      @perPage="changeSize"
                    />
                  </template>
                </m-table-view>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { MTable, MTableView, MSearchInput, MButton, MPagination } from 'gatherings-storybook'
import { filter } from '../../utilities/search'
export default {
  props: ['ecosystemId'],
  components: {
    MTable,
    MTableView,
    MSearchInput,
    MButton,
    MPagination,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    loading: false,
    searchTerm: null,
    displayedSpaces: [],
    selectedSpace: undefined,
    selectAllCheckbox: false,
    headers: [
      {
        value: 'name',
        text: 'Name',
      },
      {
        value: 'attendees',
        text: 'Attendees',
      },
      {
        value: 'menu',
        sortable: false,
        width: '56px',
        align: 'center',
      },
    ],
    contextMenuProps: {
      left: true,
      'nudge-left': '12px',
    },
    sortBy: 'name',
    sortDesc: true,
    pageNumber: 1,
    perPage: 10,
  }),
  computed: {
    spaces() {
      return this.$store.getters['ecosystems/getById'](this.ecosystemId)?.gatherings || []
    },
    filteredSpaces() {
      const filters = {
        search: item => this.searchTable(item) ?? item,
      }
      const tableFilter = filter(this.spaces, filters)
      return tableFilter
    },
    allDisplayedSelected() {
      return this.selectedDisplayedSpaces.length === this.displayedSpaces.length
    },
    someDisplayedSelected() {
      return this.selectedDisplayedSpaces.length > 0 && !this.allDisplayedSelected
    },
    titleRowHeight() {
      return this.windowWidth <= 540 ? '200px' : '120px'
    },
  },
  methods: {
    getSpaces() {
      return this.$store.dispatch('ecosystems/fetchSpaces', this.ecosystemId)
    },
    getEcosystem() {
      return this.$store.dispatch('ecosystems/fetchById', this.ecosystemId)
    },
    createSpace() {
      this.$router.push({
        name: 'Create',
        params: { workspaceId: this.ecosystemId },
      })
    },
    editSpace(space) {
      this.$router.push({
        name: 'Edit',
        params: { workspaceid: this.ecosystemId, spaceId: space.id },
      })
    },
    searchTable(item) {
      if (!this.searchTerm) {
        return
      } else {
        return item.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())
      }
    },
    setDisplayedSpaces(spaces) {
      this.displayedSpaces = spaces
    },
    changeSize(size) {
      this.perPage = size

      if (size >= this.filteredSpaces.length) {
        this.pageNumber = 1
      }
      if (this.pageNumber > this.numberOfPages) {
        this.pageNumber = this.numberOfPages
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    await this.getEcosystem()
    await this.getSpaces()
  },
}
</script>
<style scoped lang="scss">
.spaces-list {
  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }

  @media (min-width: 1264px) {
    max-width: 1120px;
  }

  @media (min-width: 1904px) {
    max-width: 1240px;
  }

  &__table,
  &__heading,
  .button {
    :deep(*) {
      font-family: 'Mulish' !important;
    }
  }
  &__sub-title {
    font-family: 'Mulish' !important;
    font-size: 1.25rem;
  }

  &__table :deep(tbody tr) {
    cursor: pointer;
  }
}
.sub-title {
  font-size: 1.3rem;
}
.table-search-bar {
  flex: content;
}
.table-toolbar {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  :deep(*) {
    font-family: 'Mulish' !important;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    :deep(.v-input__slot) {
      margin-bottom: 0;
      .v-label {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $black;
      }
    }
  }
}
.space-name {
  font-weight: 700;
  line-height: 20px;
  color: $black;
}
</style>

<style lang="scss">
.context-menu.list {
  border: none;

  .list-item__content {
    font-family: 'Mulish';
  }
}
</style>
